import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OddButton from 'components/match/odd_button';
import { OddsValueTypeTextCreator } from 'toolkit/utils';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { EnumMarketName } from 'toolkit/Enums';
import { useMediaQuery } from 'react-responsive';

function BetBox(props) {
  const { providerId, marketStringId, outcomeId } = props;
  const producerStatus = useSelector((state) => state.match.producerStatus);
  const match = useSelector((state) => state.match.events[providerId]);
  const [betTitle, setBetTitle] = useState('');
  const [disabled, setDisabled] = useState(false);
  const nonTitledMarkets = [
    EnumMarketName.ANYTIMEGOALSCORER_40,
    EnumMarketName.NEXTGOALSCORER_38,
    EnumMarketName.LASTGOALSCORER_39
  ];
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  useEffect(() => {
    try {
      const market = match.markets?.find((m) => m.stringId === marketStringId);
      const outcome = market?.outcomes.find((o) => o.id === outcomeId);
      const title = OddsValueTypeTextCreator(
        match.sport.toString(),
        market?.id.toString(),
        outcome?.id,
        outcome?.name
      );
      setDisabled(
        market?.marketStatus !== 6 ||
          (market?.producer === 1 && producerStatus.preMatch === false) ||
          (market?.producer === 2 && producerStatus.live === false)
      );
      if (!nonTitledMarkets.includes(market?.id)) setBetTitle(title);
    } catch (error) {
      console.log(error);
    }
  }, [match, producerStatus.live, producerStatus.preMatch]);

  if (disabled)
    return (
      <>
        <div
          className={`d-flex flex-column text-center fs-8 ${
            isDesktop ? 'text-white' : 'text-dark'
          }`}>
          <span>{betTitle}</span>
        </div>

        {isDesktop ? (
          <div
            className="border-tertiary rounded flex-center p-1 w-100 mt-auto"
            style={{ backgroundColor: '#696767' }}>
            <span className="flex-center" style={{ width: '2.05rem' }}>
              <img
                className="icon-white"
                width="17"
                height="18"
                src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`}
              />
            </span>
          </div>
        ) : (
          <div className="border-tertiary rounded flex-center p-1 w-100 mt-auto">
            <span className="flex-center" style={{ width: 25 }}>
              <img width="17" height="18" src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`} />
            </span>
          </div>
        )}
      </>
    );

  return (
    <>
      {props.showOddTitle && (
        <div
          className={`d-flex flex-column text-center fs-8 ${
            isDesktop ? 'text-white' : 'text-dark'
          }`}>
          <span>{betTitle === '' ? '\u00A0' : betTitle}</span>
        </div>
      )}
      <OddButton
        providerId={props.providerId}
        outcomeId={props.outcomeId}
        marketStringId={props.marketStringId}
      />
    </>
  );
}

BetBox.defaultProps = {
  showOddTitle: true
};

export default BetBox;
